
import React from "react"
import styles from "./styles.module.css"
import appleiosstore  from "../../images/app-store-badge-new.png"
import Googleplaystore  from "../../images/google-play-badge.png"
import paytm from "../../images/paytm_logo.png"
import amazon from "../../images/amazon_logo.png"
import flipkart from "../../images/flipkart_logo.png"
import facebook from "../../images/facebook.png"
import twitter from "../../images/twitter.png"
import linkedin from "../../images/linkedin.png"
import { Link } from "gatsby";

const FFooter = () => (
   <footer className={styles.footerArea}>
        <div className={["container"].join(" ")}>
            <div className={[styles.footer_top_section].join(' ')}>
                <div className={["row", "justify-content-between"].join(" ")}>
                    <div className={["col-6", "col-sm-6", "col-lg-2"].join(" ")}>
                        <div className={styles.singleFooterWidget}>
                            <h4 className={styles.widgetTitle}>MProfit</h4>
                            <nav>
                            <ul className={styles.ourLink}>
                                    <li><Link to="/features">Features</Link></li>
                                    <li><Link to="/pricing">Pricing</Link></li>
                                    <li><Link to="/sign-up">Sign Up</Link></li>
                                    {/* <li><Link to="/download">Download</Link></li> */}
                                    {/* <li><Link to="/clients">Clients</Link></li>   */}
                                    <li><Link to="/reviews">Reviews</Link></li>
                                    <li><Link to="/import">Import</Link></li>        
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className={["col-6", "col-sm-6", "col-lg-2"].join(" ")}>
                        <div className={styles.singleFooterWidget}>
                            <h4 className={styles.widgetTitle}>Company</h4>
                            <nav>
                                <ul className={styles.ourLink}>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>  
                                    <li><Link to="/terms-of-use">Terms of Use</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li> 
                                    <li><Link to="/refer">Referral Program</Link></li> 
                                    <li><a href="https://careers.mprofit.in" target="_blank">Careers</a></li>
                                    {/* <li><Link to="/news">News</Link></li> */}
                                    {/* <li><Link to="/careers">Careers</Link></li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className={["col-6", "col-sm-6", "col-lg-2"].join(" ")}>
                        <div className={styles.singleFooterWidget}>
                            <h4 className={styles.widgetTitle}>Support</h4>
                            <nav>
                                <ul className={styles.ourLink}>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><a href="https://help.mprofit.in/" target="_blank" rel="noopener noreferrer">MProfit Help Center</a></li>
                                    {/* <li><a href="https://forum.mprofit.in/" target="_blank" rel="noopener noreferrer">Forum - Desktop</a></li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className={["col-12", "col-sm-6", "col-lg-6"].join(" ")}>
                        <div className={styles.singleFooterWidget}>
                            <h4 className={styles.widgetTitle}>MProfit For Mobile: Download</h4>
                            <div className={styles.link_btn}>
                            <a href="https://itunes.apple.com/in/app/mprofit/id1418536415?ls=1&mt=8" target="_blank" rel="noopener noreferrer"><img src={appleiosstore} alt="" height="40"/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.mprmobile&hl=en" target="_blank" rel="noopener noreferrer"><img src={Googleplaystore} alt="" height="40"/></a>
                            </div>
                            {/* <h4 className={styles.widgetTitle}>Also available on</h4>
                            <div className={styles.link_btn}>
                                <a href="http://www.flipkart.com/search?q=mprofit&as=off&as-show=off&otracker=start" target="_blank" rel="noopener noreferrer"><img src={flipkart} alt=""  height="50px"/></a>
                                <a href="http://www.amazon.in/s/ref=sr_nr_n_1?fst=as%3Aoff&rh=n%3A5490080031%2Ck%3Amprofit&keywords=mprofit&ie=UTF8&qid=1429681785&rnid=3576079031" target="_blank" rel="noopener noreferrer"><img src={amazon}alt=""  height="50px"/></a>
                                <a href="https://paytm.com/shop/search?category=9858&q=mprofit" target="_blank" rel="noopener noreferrer"><img src={paytm} alt="" height="50px"/></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className={[styles.fBottom,'pt-4', 'pb-4'].join(' ')}>
                <div className="row">
                    <div className={[styles.footerSocialInfo,'col-md-6','text-md-right', 'order-md-2'].join(' ')}>
                        <span>Connect with us</span>
                        <a href="https://www.facebook.com/MProfitSoftware/" target="_blank" rel="noopener noreferrer" className="facebook" data-toggle="tooltip" data-placement="top" title="Facebook"><i className={["fa", "fa-facebook", styles.socialLink].join(" ")} aria-hidden="true"></i></a>
                        <a href="https://twitter.com/mprofit?lang=en" target="_blank" rel="noopener noreferrer" className="twitter" data-toggle="tooltip" data-placement="top" title="Twitter"><i className={["fa", "fa-twitter", styles.socialLink].join(" ")} aria-hidden="true"></i></a>
                        <a href="https://in.linkedin.com/company/mprofit" target="_blank" rel="noopener noreferrer" className={styles.linkedin} data-toggle="tooltip" data-placement="top" title="linkedin"><i className={["fa", "fa-linkedin", styles.socialLink].join(" ")} aria-hidden="true"></i></a>
                    </div>
                    <div className={[styles.copyrightText,'col-md-6', 'order-md-1'].join(' ')}>
                        MProfit Software Private Limited © 2025
                    </div> 
                </div>
            </div>
        </div>
    </footer> 
)


export default FFooter
